import { BaseModel } from 'modules/core/models/base-model';

const NOT_STARTED                          = 1,
      IN_PROGRESS_WRITING_HEADERS_FOOTERS  = 2,
      IN_PROGRESS_FINISHED_HEADERS_FOOTERS = 3,
      IN_PROGRESS_READING                  = 4,
      IN_PROGRESS_FINISHED_READING         = 5,
      FINISHED                             = 6,
      CANCELED                             = 7,
      INVALID                              = 8,
      REVISION_VALIDATED                   = 9,
      MISSING_FILES                        = 10;

const IN_PROGRESS = [
    IN_PROGRESS_WRITING_HEADERS_FOOTERS,
    IN_PROGRESS_FINISHED_HEADERS_FOOTERS,
    IN_PROGRESS_READING,
    IN_PROGRESS_FINISHED_READING,
    REVISION_VALIDATED,
];

const IN_PROGRESS_FINISHED = [
    IN_PROGRESS_FINISHED_HEADERS_FOOTERS,
    IN_PROGRESS_FINISHED_READING,
];

export class ProcessStatus extends BaseModel {
    id      = null;
    message = null;

    static get NOT_STARTED() {
        return NOT_STARTED;
    }

    static get IN_PROGRESS_WRITING_HEADERS_FOOTERS() {
        return IN_PROGRESS_WRITING_HEADERS_FOOTERS;
    }

    static get IN_PROGRESS_FINISHED_HEADERS_FOOTERS() {
        return IN_PROGRESS_FINISHED_HEADERS_FOOTERS;
    }

    static get IN_PROGRESS_READING() {
        return IN_PROGRESS_READING;
    }

    static get IN_PROGRESS_FINISHED_READING() {
        return IN_PROGRESS_FINISHED_READING;
    }

    static get FINISHED() {
        return FINISHED;
    }

    static get CANCELED() {
        return CANCELED;
    }

    static get INVALID() {
        return INVALID;
    }

    static get REVISION_VALIDATED() {
        return REVISION_VALIDATED;
    }

    static get IN_PROGRESS() {
        return IN_PROGRESS;
    }

    static get IN_PROGRESS_FINISHED() {
        return IN_PROGRESS_FINISHED;
    }

    static get MISSING_FILES() {
        return MISSING_FILES;
    }

    /**
     * gets the icon by status id
     *
     * @param statusId
     * @return {string}
     * @constructor
     */
    static getIconByStatusId(statusId) {
        switch (statusId) {
            case undefined:
            case ProcessStatus.NOT_STARTED:
                return 'icon-file-empty2 text-muted';
            case ProcessStatus.REVISION_VALIDATED:
                return 'icon-file-empty2 text-success';
            case ProcessStatus.IN_PROGRESS_READING:
            case ProcessStatus.IN_PROGRESS_WRITING_HEADERS_FOOTERS:
                return 'icon-file-stats2 text-warning';
            case ProcessStatus.FINISHED:
            case ProcessStatus.IN_PROGRESS_FINISHED_READING:
            case ProcessStatus.IN_PROGRESS_FINISHED_HEADERS_FOOTERS:
                return 'icon-file-check2 text-success';
            case ProcessStatus.CANCELED:
            case ProcessStatus.INVALID:
                return 'icon-file-minus2 text-danger';
            case ProcessStatus.MISSING_FILES:
                return 'icon-file-empty2 text-yellow-600';
            default:
                return 'icon-file-stats2 text-warning';
        }
    }

    /**
     * gets the tooltip by status id
     *
     * @param statusId
     * @return {string}
     * @constructor
     */
    static getTooltipByStatusId(statusId) {
        switch (statusId) {
            case undefined:
            case ProcessStatus.NOT_STARTED:
                return 'form.messages.processing.not-started';
            case ProcessStatus.REVISION_VALIDATED:
                return 'form.messages.processing.revision-validated';
            case ProcessStatus.IN_PROGRESS_READING:
            case ProcessStatus.IN_PROGRESS_WRITING_HEADERS_FOOTERS:
                return 'form.messages.processing.in-progress';
            case ProcessStatus.FINISHED:
            case ProcessStatus.IN_PROGRESS_FINISHED_READING:
            case ProcessStatus.IN_PROGRESS_FINISHED_HEADERS_FOOTERS:
                return 'form.messages.processing.completed';
            case ProcessStatus.INVALID:
                return 'form.messages.processing.invalid';
            case ProcessStatus.CANCELED:
                return 'form.messages.processing.canceled';
            default:
                return 'form.messages.processing-the-status';
        }
    }
}
